import React, { useEffect, useState, useRef } from 'react';
import { Box, Image, Text } from "@chakra-ui/react";
import Rest from "../../assets/image/rabbit-rest.png";
import Up from "../../assets/image/rabbit-up.png";
import DownToUp from "../../assets/image/rabbit-down-to-up.gif";
import UpToDown from "../../assets/image/rabbit-up-to-down.gif";
import moment from 'moment';

const Option = (props) => {
    const page = props.page;
    const order = props.order; 
    const score = props.score;
    const index = props.index; // 該page的第幾題(由上到下)
    const [isSelect, setIsSelect] = useState(false);
    const [isInit, setIsInit] = useState(true);
    const [img, setImg] = useState(Rest);

    useEffect(() => {
        checkRabbit();
    }, [props.nowScore]);

    useEffect(() => {
        checkRabbit();
    }, [props.checkCount]);

    const checkRabbit = () => {
        // console.log("checkRabbit: " + score + " " + props.nowScore)
        let newScore = parseInt(localStorage.getItem("p" + page + "_q" + order, score));
        if(isInit) {
            setIsInit(false);
            if(newScore === score) {
                setIsSelect(true);
                setImg(Up);
            }
            else {
                setIsSelect(false);
                setImg(Rest);
            }
            return;
        }
        if(score === newScore) {
            setTimeout(() => {
                if(isSelect) {
                    setImg(Up);
                }
                else {
                    setImg(Rest);
                }
            }, 500);
        }
        else {
            setIsSelect(false);
            if(img === Up) {
                setImg(UpToDown);
                setTimeout(() => {
                    if(!isSelect) {
                        setImg(Rest);
                    }
                }, 500);
            }
            else {
                setImg(Rest);
            }
        }
    };

    const handleClick = () => {
        if(!isSelect) {
            props.scoreChange(score);
            setIsSelect(true);
            setImg(DownToUp);
            localStorage.setItem("last_click_" + order, moment().format("YYYY-MM-DD HH:mm:ss"));
            localStorage.setItem("last_click_index", index);
        }
    }
    
    return (
        <>
            <Image
                id={"p" + page + "_q" + order + "_o" + score}
                src={img}
                w="14vw"
                data-select={isSelect}
                cursor="pointer"
                onClick={handleClick}
            />
        </>
    );
}

export default Option;