import { Box, Image, Text, VStack } from "@chakra-ui/react";
import background from "../../assets/image/p5-bg.png";
import mainImg from "../../assets/image/p5-main.gif";

const HeaderPage5 = () => {
    return (
        <>
            <Box
                mb="8rem"
            >
                <Image
                    src={background} 
                    w="100vw"
                    h="500px"
                    zIndex="-1"
                />
                <Box
                    position="absolute"
                    top="100px"
                    left="0vw"
                    w="100vw"
                >
                    <VStack
                        spacing="1em"
                        position="absolute"
                        w="100%"
                    >
                        <Text 
                            fontSize="18px"
                            color="black"
                            w="100%"
                            align="center"
                            margin={0}
                            mt="1em"
                        >
                            「唉…好久沒下雨了，蘿蔔都種不出來...」
                        </Text>
                        <Text 
                            fontSize="16px"
                            color="black"
                            w="80%"
                            left="10%"
                            align="center"
                        >
                            你聽見農夫兔嘆氣後，決定要幫助他，而下列哪一種是你喜歡展現的能力呢？
                        </Text>
                    </VStack>
                    <Image 
                        src={mainImg} 
                        w="100%"
                        h="auto"
                        zIndex=""
                        mt="9rem"
                    />
                </Box>
            </Box>
        </>
    );
}

export default HeaderPage5;