import React, { useEffect, useState } from 'react';
import { Box, Center, Image, Text, VStack, HStack } from "@chakra-ui/react";
import Option from './Option';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';
import moment from 'moment';

const Question = (props) => {
    const page = props.page;
    const order = props.order;
    const index = props.index;
    const options = [0, 1, 2, 3, 4, 5];
    const hints = props.hints;
    const [score, setScore] = useState(0);
    const [checkCount, setCheckCount] = useState(0);
    const id = "question_" + index;

    useEffect(() => {
        const intervalId = setInterval(() => {
            let last_click = localStorage.getItem("last_click_" + order);
            try {
                if(moment().diff(moment(last_click), 'seconds') > 2) {
                    setCheckCount(checkCount + 1);
                    localStorage.removeItem("last_click_" + order);
                }
            } catch(e) {
                localStorage.removeItem("last_click_" + order);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const scoreChange = (score) => {
        setScore(score);
        localStorage.setItem("p" + page + "_q" + order, score);
        setTimeout(() => {
            setCheckCount(checkCount + 1);
        }, 500);

        if(index < 5) {
            // document.getElementById('question_' + index).scrollIntoView({
            //     alignToTop: false,
            //     behavior: 'smooth',
            //     block: 'start'
            // });
            
            // get top of document.getElementById('question_' + index)
            let top = document.getElementById('question_' + index).getBoundingClientRect().top - 150;
            // window scroll to top
            window.scrollBy({
                top: top,
                behavior: 'smooth'
            });
        }
    }

    return (
        <>
            <Box
                id={id}
            >
                <VStack>
                    <Center
                        w="100vw"
                    >
                        <Image
                            src={props.icon}
                            w="20px"
                        />
                    </Center>
                    <Text
                        fontSize="16px"
                        color="black"
                        textAlign="center"
                        margin="10px !important"
                    >
                        {props.question}
                    </Text>
                    <HStack
                        w="90vw"
                        spacing="0px"
                        justify="space-between"
                        left="5vw"
                        mt="0px !important"
                    >
                        {options.map((option, i) => {
                            return (
                                <Option
                                    key={"p" + page + "_q" + order + "_o" + i}
                                    page={page}
                                    order={order}
                                    score={option}
                                    index={index}
                                    scoreChange={scoreChange}
                                    nowScore={score}
                                    checkCount={checkCount}
                                />
                            )
                        })}
                    </HStack>
                    <HStack
                        w="90vw"
                        spacing="0px"
                        justify="space-between"
                        left="0"
                        mt="0 !important"
                    >
                        <LeftArrow
                            hint={hints[0]}
                        />
                        <RightArrow
                            hint={hints[1]}
                        />
                    </HStack>
                </VStack>
            </Box>
        </>
    );
}

export default Question;