import Homepage from "../pages/HomePage";
import NickNamePage from "../pages/NickNamePage";
import InputPage from "../pages/InputPage";
import ResultPage from "../pages/ResultPage";

const routes = [
    {
        path: "/",
        component: <Homepage />,
    },
    {
        path: "/nickname",
        component: <NickNamePage />,
    },
    {
        path: "/input/:page",
        component: <InputPage />,
    },
    {
        path: "/result/:test_code/:code",
        component: <ResultPage />,
    },
];

export default routes;