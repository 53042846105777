import { Image } from "@chakra-ui/react";

const Progress = ({ progress }) => {
    return (
        <>
            <Image
                src={progress}
                w="100%"
                h="auto"
            />
        </>
    );
}

export default Progress;