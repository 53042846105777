import { Box } from "@chakra-ui/react";

const Divider = ({ color, mt, mb, opacity }) => {
    const border = "1px solid " + color;
    mt = mt ? mt + " !important" : "1rem !important";
    mb = mb ? mb + " !important" : "1rem !important";
    opacity = opacity ? opacity : 1;
    return (
        <>
            <Box
                border={border}
                w="100%"
                mt={mt}
                mb={mb}
                opacity={opacity}
            />
        </>
    );
}

export default Divider;