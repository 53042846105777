import { HStack, Image, Text } from "@chakra-ui/react";
import Arror from '../../assets/image/arrow-right.png';

const RightArrow = ({ hint }) => {
    return (
        <>
            <HStack
                w="50vw"
                pl="5px"
            >
                <Image
                    src={Arror}
                    w="65%"
                />
                <Text
                    fontSize="14px"
                    color="#B7B7B7"
                    w="35%"
                    textAlign="right"
                >
                    {hint}
                </Text>
            </HStack>
        </>
    );
}

export default RightArrow;