import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, VStack, Center, HStack } from "@chakra-ui/react";
import Progress from "../components/progress";
import Question from '../components/input/Question';
import Divider from '../components/input/Divider';
import GeneratingModal from '../components/GeneratingModal';

import HeaderPage1 from '../components/input/HeaderPage1';
import HeaderPage2 from '../components/input/HeaderPage2';
import HeaderPage3 from '../components/input/HeaderPage3';
import HeaderPage4 from '../components/input/HeaderPage4';
import HeaderPage5 from '../components/input/HeaderPage5';
import HeaderPage6 from '../components/input/HeaderPage6';

const bgColors = ["", "#FDF6C0", "#FFF7EF", "#F0F9FF", "#EFE7F4", "#E9FFD4", "#FFFBDE"];
const dividerColors = ["", "#F8E449", "#FFD0BE", "#BCDEF6", "#CAA9DD", "#B2E792", "#FFDA73"];
const questions = [
    [],
    [
        "你是溫馨助人王",
        "你是點子創意王",
        "你是推理偵探王",
        "你是動手技術王",
        "你是領導帶頭王",
        "你是精確仔細王",
    ],
    [
        "善觀察分析、理性思考、邏輯推理、追根究柢",
        "多做少言、踏實實際、有耐心、坦誠直率",
        "情感豐富、敏銳直覺、彈性創意、追求美感",
        "精力充沛、有行動力、領導性、有企圖心及冒險性",
        "細心謹慎、規律條理、效率規矩、精確可靠",
        "親和健談、善解人意、愛心助人、具洞察力",
    ],
    [
        "理財性社團：保險理財社",
        "領導性社團：領袖培訓社",
        "服務性社團：助人技巧社",
        "文藝性社團：電影創作社",
        "益智性社團：魔術破解社",
        "技藝性社團：機器人組裝社",
    ],
    [
        "常被稱讚「仔細、有條理」，房間物品隨時擺放整齊",
        "喜歡在班上擔任幹部，帶領同學做事或活動",
        "經常主動熱心關懷有需要的同學，協助他們解決問題或完成任務",
        "在寫作或畫畫或音樂或表演中，有拿手的表現",
        "喜歡問「為什麼」？認真想，動腦筋去找答案",
        "喜歡拆解家裡的機器用品，再組裝回去",
    ],
    [
        "指示清楚，有既定規則和步驟，可施展動手操作的能力",
        "需要進行研究和實驗，可發揮思考和推理的能力",
        "講求精確和規矩，可施展細心有條理的能力",
        "充滿隨性和變化，可發揮創意和表達美感",
        "需和不同類型的人接觸，可發揮溝通及助人的能力",
        "擔任部門主管賦予權力，可發揮管理和領導的能力",
    ],
    [
        "財務團隊：銀行融資，財務控管，股票上市",
        "文創團隊：產品命名，美化包裝，廣告設計，發展相關文創商品",
        "研究團隊：進實驗室研發蘿蔔，進行品質改良及營養價值研究",
        "行銷團隊：營運管理，産品行銷，發展銷售網路",
        "種植團隊：投入農場設法栽種出美味香甜高品質的蘿蔔",
        "公關團隊：接待觀光團體，導覽農場，從事農場相關公益及教育訓練活動",
    ]
];
const question_orders = [
    [],
    [4, 3, 2, 1, 5, 6],
    [2, 1, 3, 5, 6, 4],
    [6, 5, 4, 3, 2, 1],
    [6, 5, 4, 3, 2, 1],
    [1, 2, 6, 3, 4, 5],
    [6, 3, 2, 5, 1, 4]
];
const hints = [
    [],
    ["不符合", "全符合"],
    ["不符合", "全符合"],
    ["沒興趣", "超吸引"],
    ["不符合", "全符合"],
    ["不喜歡", "很喜歡"],
    ["不符合", "全符合"],
];

const InputPage = () => {
    let { page } = useParams();
    const navigate = useNavigate();
    const [header, setHeader] = useState(null);
    const [showGenerating, setShowGenerating] = useState(false);
    const [prevClass, setPrevClass] = useState("btn-function");
    const [nextClass, setNextClass] = useState("btn-function");
    const progress_gif = require(`../assets/image/p${page}.gif`);
    const icon = require(`../assets/image/p${page}-icon.png`);
    const ques = questions[page];
    let isSend = false;

    useEffect(() => {
        if(!localStorage.getItem("nickname")) {
            window.location.href = "/";
            return;
        }
        if(isNaN(page) || page < 1 || page > 6) {
            window.location.href = "/";
            return;
        }
        document.body.classList.remove("body-fixed");
        switch(page) {
            case "1":
                localStorage.setItem("answer_start_time", getTimeStamp());
                setHeader(<HeaderPage1 />);
                break;
            case "2":
                setHeader(<HeaderPage2 />);
                break;
            case "3":
                setHeader(<HeaderPage3 />);
                break;
            case "4":
                setHeader(<HeaderPage4 />);
                break;
            case "5":
                setHeader(<HeaderPage5 />);
                break;
            case "6":
                setHeader(<HeaderPage6 />);
                break;
            default:
                break;
        }

        setPrevClass("btn-function btn-prev-" + page);
        setNextClass("btn-function btn-next-" + page);

        setTimeout(() => {
            document.getElementById('root').scrollIntoView();
        }, 500);

        const beforeunloadCallback = (event) => {
            event.preventDefault();
            sendLog("exit", page, localStorage.getItem("last_click_index"));
            return "";
        };
        
        window.addEventListener("beforeunload", beforeunloadCallback);
        return () => {
            window.removeEventListener("beforeunload", beforeunloadCallback);
        };
    }, [page]);

    const prevPage = () => {
        sendLog("prev", page, localStorage.getItem("last_click_index"));
        localStorage.removeItem("last_click_index");
        if(page === "1") {
            window.history.pushState({}, null, "/nickname");
            navigate("/nickname", { replace: true });
        }
        else {
            window.history.pushState({}, null, "/input/" + (parseInt(page) - 1));
            navigate("/input/" + (parseInt(page) - 1), { replace: true });
        }
    }

    const nextPage = () => {
        let empty = false;
        for(let i = 1; i <= 6; i++) {
            let score = localStorage.getItem("p" + page + "_q" + i);
            if(!score) {
                empty = true;
                break;
            }
        }
        if(empty) {
            alert("未填寫完畢");
            return;
        }
        if(page === "6") {
            if(isSend) {
                return;
            }
            isSend = true;
            console.log("send");
            setShowGenerating(true);
            sendLog("submit", page, localStorage.getItem("last_click_index"));
            setTimeout(() => {
                calculateScore();
            }, 500);
        }
        else {
            sendLog("next", page, localStorage.getItem("last_click_index"));
            localStorage.removeItem("last_click_index");
            window.history.pushState({}, null, "/input/" + (parseInt(page) + 1));
            navigate("/input/" + (parseInt(page) + 1), { replace: true });
        }
    }

    const getTimeStamp = () => {
		let now = new Date();
		let year = now.getFullYear();
		let month = now.getMonth() + 1;
		let date = now.getDate();
		let hour = now.getHours();
		let minute = now.getMinutes();
		let second = now.getSeconds();
		return year + "/" + month + "/" + date + " " + hour + ":" + minute + ":" + second;
	}

	const getAnswerSeconds = () => {
        if(!localStorage.getItem("answer_start_time") || !localStorage.getItem("answer_end_time")) {
            return 0;
        }
		let start = new Date(localStorage.getItem("answer_start_time"));
		let end = new Date(localStorage.getItem("answer_end_time"));
		let diff = end.getTime() - start.getTime();
		return Math.floor(diff / 1000);
	}

    const calculateScore = () => {
        let R = 0;
		let I = 0;
		let A = 0;
		let S = 0;
		let E = 0;
		let C = 0;
        for(let i = 1; i <= 6; i++) {
            R += localStorage.getItem("p" + i + "_q1") && !isNaN(localStorage.getItem("p" + i + "_q1")) ? parseInt(localStorage.getItem("p" + i + "_q1")) : 0;
            I += localStorage.getItem("p" + i + "_q2") && !isNaN(localStorage.getItem("p" + i + "_q2")) ? parseInt(localStorage.getItem("p" + i + "_q2")) : 0;
            A += localStorage.getItem("p" + i + "_q3") && !isNaN(localStorage.getItem("p" + i + "_q3")) ? parseInt(localStorage.getItem("p" + i + "_q3")) : 0;
            S += localStorage.getItem("p" + i + "_q4") && !isNaN(localStorage.getItem("p" + i + "_q4")) ? parseInt(localStorage.getItem("p" + i + "_q4")) : 0;
            E += localStorage.getItem("p" + i + "_q5") && !isNaN(localStorage.getItem("p" + i + "_q5")) ? parseInt(localStorage.getItem("p" + i + "_q5")) : 0;
            C += localStorage.getItem("p" + i + "_q6") && !isNaN(localStorage.getItem("p" + i + "_q6")) ? parseInt(localStorage.getItem("p" + i + "_q6")) : 0;
        }
        // console.log(R, I, A, S, E, C);
        R *= 3;
        I *= 3;
        A *= 3;
        S *= 3;
        E *= 3;
        C *= 3;
        localStorage.setItem("answer_end_time", getTimeStamp());
        let answer_second = getAnswerSeconds();
        // console.log(answer_second)
        sendResult(R, I, A, S, E, C, answer_second);
    }

    const sendResult = (R, I, A, S, E, C, answer_second) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/x-www-form-urlencoded");
        headers.append("Accept", "application/json");
        
        let urlencoded = new URLSearchParams();
        urlencoded.append("uuid", localStorage.getItem("uuid"));
        urlencoded.append("nickname", localStorage.getItem("nickname"));
        urlencoded.append("R", R);
        urlencoded.append("I", I);
        urlencoded.append("A", A);
        urlencoded.append("S", S);
        urlencoded.append("E", E);
        urlencoded.append("C", C);
        urlencoded.append("answer_second", answer_second);

        let requestOptions = {
            method: 'POST',
            headers: headers,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch("https://eporttw.com/prior/test/api/result", requestOptions)
            .then(response => response.text())
            .then(result => {
                // console.log(result);
                try {
                    result = JSON.parse(result);
                } catch(e) {
                    console.log(e);
                    alert("送出時發生錯誤，請稍後再試");
                    return;
                }
                if(result.success && result.test_code) {
                    localStorage.clear();
                    // localStorage.setItem("test_code", result.test_code);
                    // localStorage.setItem("code", result.code);
                    // localStorage.setItem("show_img_url", result.show_img_url);
                    // localStorage.setItem("result_img_url", result.result_img_url);
                    setTimeout(() => {
                        window.history.pushState({}, null, "/result/" + result.test_code + "/" + result.code);
                        navigate("/result/" + result.test_code + "/" + result.code, { replace: true }); 
                    }, 500);
                }
                else {
                    alert("送出時發生錯誤，請稍後再試");
                }
            })
            .catch(error => {
                console.log('error', error);
                alert("送出時發生錯誤，請稍後再試");
            });
    }

    const sendLog = (type, page, index) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/x-www-form-urlencoded");
        headers.append("Accept", "application/json");
        
        let urlencoded = new URLSearchParams();
        urlencoded.append("uuid", localStorage.getItem("uuid"));
        urlencoded.append("type", type);
        urlencoded.append("page", page);
        urlencoded.append("index", index);

        let requestOptions = {
            method: 'POST',
            headers: headers,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch("https://eporttw.com/prior/test/api/action/log", requestOptions)
            .then(response => response.text())
            .then(result => {
                // console.log(result);
                try {
                    result = JSON.parse(result);
                } catch(e) {
                    console.log(e);
                    return;
                }
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    return (
        <>
            <Box
                key={"page" + page}
                w="100vw"
                minH="var(--doc-height)"
                pb="5vh"
                backgroundColor={bgColors[page]}
                className="bg-animation"
            >
                <Box
                    position="relative"
                    align="center"
                    w="70vw"
                    pt="15px"
                    ml="15vw"
                    h="65px"
                    zIndex="1"
                >
                    <Progress progress={progress_gif} />
                </Box>
                <VStack
                    mt="-80px"
                    position="relative"
                >
                    {header ? header : <></>}
                </VStack>
                <VStack>
                    {ques.map((que, index) => {
                        return (
                            <Box
                                key={index}    
                            >
                                <Question 
                                    page={page}
                                    order={question_orders[page][index]}
                                    question={que}
                                    icon={icon}
                                    hints={hints[page]}
                                    index={index}
                                />
                                {index !== ques.length - 1 ? 
                                    <Divider 
                                        color={dividerColors[page]}
                                    /> 
                                    : 
                                    <></>
                                }
                            </Box>
                        )
                    })}
                </VStack>
                <HStack
                    w="90vw"
                    ml="5vw"
                    mt="5vh"
                >
                    <Center
                        w="50%"
                    >
                        <Box 
                            w="80%"
                            bottom="8vh"
                            borderRadius={25}
                            pt={16}
                            pb={16}
                            pl={48}
                            pr={48}
                            textAlign="center"
                            background="#B7B7B7"
                            color="white"
                            fontSize="16px"
                            cursor="pointer"
                            className={prevClass}
                            onClick={prevPage}
                        >
                            上一題
                        </Box>
                    </Center>
                    <Center
                        w="50%"
                    >
                        <Box 
                            w="80%"
                            bottom="8vh"
                            borderRadius={25}
                            pt={16}
                            pb={16}
                            pl={48}
                            pr={48}
                            textAlign="center"
                            background="#1B67B2"
                            color="white"
                            fontSize="16px"
                            cursor="pointer"
                            className={nextClass}
                            onClick={nextPage}
                        >
                            下一題
                        </Box>
                    </Center>
                </HStack>
            </Box>
            {
                page === "6" ?
                    <GeneratingModal 
                        isOpen={showGenerating}
                    />
                :
                    <></>
            }
        </>
    )
};

export default InputPage;