import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, VStack, Image, Input, Text, Center } from "@chakra-ui/react";
import background from "../assets/image/nickname-bg.png";
import Progress from "../components/progress";
import p0 from "../assets/image/p0.gif";

// Preload
import Rest from "../assets/image/rabbit-rest.png";
import Up from "../assets/image/rabbit-up.png";
import bg1 from "../assets/image/p1-bg.png";
import bg2 from "../assets/image/p2-bg.png";
import bg3 from "../assets/image/p3-bg.png";
import bg4 from "../assets/image/p4-bg.png";
import bg5 from "../assets/image/p5-bg.png";
import bg6 from "../assets/image/p6-bg.png";

const NickNamePage = () => {
    const navigate = useNavigate();
    const [nickname, setNickname] = useState("");
    const [inputHighLight, setInputHighLight] = useState(false);
    const [inputErrorText, setInputErrorText] = useState("");

    useEffect(() => {
        setNickname(localStorage.getItem("nickname") || "");

        document.getElementById('root').scrollIntoView();
    }, []);

    const handleNicknameInput = (e) => {
        // if(e.target.value !== "" && !/^[a-zA-Z0-9\u4e00-\u9fa5]+$/.test(e.target.value)) {
        //     setInputHighLight(true);
        //     setInputErrorText("暱稱不可包含特殊符號");
        //     setNickname(e.target.value.slice(0, -1));
        // }
        // else 
        if(e.target.value !== "" && e.target.value.length > 8) {
            setInputHighLight(true);
            setInputErrorText("暱稱長度不可超過8個字");
            setNickname(e.target.value.slice(0, 8));
        }
        else if(e.target.value !== "") {
            setInputHighLight(false);
            setInputErrorText("");
            setNickname(e.target.value);
        }
    };

    const handleStartTest = () => {
        if (nickname === "") {
            setInputHighLight(true);
            setInputErrorText("請輸入暱稱");
            return;
        }
        localStorage.setItem("nickname", nickname);
        localStorage.setItem("uuid", _uuid().replace(/-/g, ""));
        window.history.pushState({}, null, "/input/1");
        navigate("/input/1", { replace: true });
    };
    
    const bgStyle = {
        margin: 0,
        padding: 0,
        background: `url(${background}) no-repeat center center fixed`,
        backgroundSize: "cover",
        width: "100vw",
    };

    const _uuid = () => {
        let d = Date.now();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
            d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    return (
        <Box
            key={"nickname"}
            className="bg-animation height100"
            style={bgStyle}
        >
            {/* <Image 
                src={background}
                w="100%"
                className="height100"
                position="absolute"
            /> */}
            <Box>
                <VStack
                    align="center"
                    w="70vw"
                    pt="15px"
                    ml="15vw"
                    position="relative"
                    zIndex="9999"
                >
                    <Progress progress={p0} />
                    <Text 
                        fontSize="16px"
                        mt="22px !important"
                        color="white"
                    >
                        某天，不小心掉入樹洞的你誤闖了兔子家族的年度慶典，於是你打算加入他們，並展開了一場奇幻冒險…
                    </Text>
                    <Input 
                        placeholder="輸入暱稱"
                        size="lg"
                        w="100%"
                        mt="28px !important"
                        pt={10}
                        pb={10}
                        ps={10}
                        borderRadius={5}
                        style={{
                            boxSizing: "border-box"
                        }}
                        value={nickname}
                        onInput={handleNicknameInput}
                        className={inputHighLight ? "input-highlight" : ""}
                    />
                    {
                        inputHighLight && inputErrorText ?
                        <Text
                            as="span"
                            fontSize="14px"
                            color="red"
                            mt="5px !important"
                            fontWeight="bold"
                        >
                            {inputErrorText}
                        </Text>
                        : <></>
                    }
                    
                </VStack>

                <Center
                    w="100vw"
                    className="height100"
                    position="absolute"
                    top="0"
                    alignItems="start"
                >
                    <Box 
                        w="auto"
                        mt="85vh"
                        // mt="calc(var(--vh, 1vh) * 100 * 0.85)"
                        borderRadius={25}
                        pt={16}
                        pb={16}
                        pl={48}
                        pr={48}
                        textAlign="center"
                        background="#1B67B2"
                        color="white"
                        fontSize="16px"
                        cursor="pointer"
                        className="btn-function btn-input-nickname"
                        onClick={handleStartTest}
                    >
                        輸入好了
                    </Box>
                </Center>
            </Box>
            
            {/* Preload */}
            <>
                <Image
                    src={Rest}
                    w="1px"
                    h="1px"
                    display="none"
                />
                <Image
                    src={Up}
                    w="1px"
                    h="1px"
                    display="none"
                />
                <Image
                    src={bg1}
                    w="1px"
                    h="1px"
                    display="none"
                />
                <Image
                    src={bg2}
                    w="1px"
                    h="1px"
                    display="none"
                />
                <Image
                    src={bg3}
                    w="1px"
                    h="1px"
                    display="none"
                />
                <Image
                    src={bg4}
                    w="1px"
                    h="1px"
                    display="none"
                />
                <Image
                    src={bg5}
                    w="1px"
                    h="1px"
                    display="none"
                />
                <Image
                    src={bg6}
                    w="1px"
                    h="1px"
                    display="none"
                />
            </>
        </Box>
    )
};

export default NickNamePage;