import "./styles/styles.css";
import { Route, Routes } from 'react-router-dom';
import routes from './router/router';
import { Box } from "@chakra-ui/react";
import DesktopHint from "./components/DesktopHint";

const App = () => {
	return (
		<>
			<Box className="desktop-mode" w="100vw" h="100vh">
				<DesktopHint />
			</Box>
			<Box className="mobile-mode">
				<Routes>
					{routes.map((route, index) => {
						return <Route key={index} path={route.path} element={route.component} />
					})}
				</Routes>
			</Box>
		</>
	);
}

export default App;
