import { Box, Center, Image } from "@chakra-ui/react";
import background from "../assets/image/desktop.png";
import rabbit from "../assets/image/desktop-rabbit.gif";

const DesktopHint = () => {

    const bgStyle = {
        margin: 0,
        padding: 0,
        background: `url(${background}) no-repeat center center fixed`,
        backgroundSize: "cover",
        width: "100vw",
        height: "100vh",
    };

    return (
        <>
            <Box 
                style={bgStyle}
            >
                <Center
                    w="100vw"
                    h="100vh"
                    zIndex="0"
                    alignItems="start"
                >
                    <Image
                        src={rabbit}
                        h="14.3vh"
                        w="14.3vh"
                        zIndex="1"
                        mt="28.6vh"
                    />
                </Center>
            </Box>
        </>
    )
};

export default DesktopHint;