import { HStack, Image, Text } from "@chakra-ui/react";
import Arror from '../../assets/image/arrow-left.png';

const LeftArrow = ({ hint }) => {
    return (
        <>
            <HStack
                w="50vw"
                pr="5px"
            >
                <Text
                    fontSize="14px"
                    color="#B7B7B7"
                    w="35%"
                >
                    {hint}
                </Text>
                <Image
                    src={Arror}
                    w="65%"
                />
            </HStack>
        </>
    );
}

export default LeftArrow;