import React, { useEffect, useState } from 'react';
import { Box, Center, Text, VStack } from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react';
import Lottie from "lottie-react";
import CarrotAnimation from "../assets/lottie/carrot.json";

const GeneratingModal = ({ isOpen }) => {
    const [text, setText] = useState("");
    const string = "結果分析中";
    let index = 0;
    let count = string.length;
    useEffect(() => {
        if (isOpen) {
            const interval = setInterval(() => {
                if (index < count) {
                    setText(string.slice(0, index + 1));
                    index++;
                } else {
                    clearInterval(interval);
                }
            }, 200);
            return () => clearInterval(interval);
        }
    }, [isOpen]);

    return (
        <>
            <Modal 
                isOpen={isOpen}
                scrollBehavior="inside"
            >
                <ModalOverlay
                    backgroundColor="rgba(0, 0, 0, 0.6)"
                />
                <ModalContent
                    w="90%"
                    h="90vh"
                    mt="5%"
                    left="5%"
                    backgroundColor="transparent"
                    position="relative"
                >
                    <ModalBody
                        h="100%"
                    >
                        <Center
                            h="100%"
                        >
                            <Box
                                w="200px"
                                h="200px"
                                borderRadius="12px"
                                backgroundColor="white"
                            >
                                <Center
                                    h="100%"
                                >
                                    <VStack
                                        mt="-60px !important"
                                    >
                                        <Lottie
                                            animationData={CarrotAnimation}
                                            loop={true}
                                            autoplay={true}
                                            style={{
                                                width: "200px",
                                                height: "200px",
                                            }}
                                        />
                                        <Text
                                            fontSize="20px"
                                            fontWeight="bold"
                                            color="#3D3D3D"
                                            mt="-40px !important"
                                        >
                                            {text}
                                        </Text>
                                    </VStack>
                                </Center>
                            </Box>
                        </Center>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default GeneratingModal;