import { Box, Image } from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const ResultModal = ({ isOpen, closeModal, src }) => {
    return (
        <>
            <Modal 
                isOpen={isOpen}
                scrollBehavior="inside"
            >
                <ModalOverlay
                    backgroundColor="rgba(0, 0, 0, 0.6)"
                />
                <ModalContent
                    w="90%"
                    h="90vh"
                    mt="5%"
                    left="5%"
                    backgroundColor="transparent"
                    position="relative"
                >
                    <Box
                        position="absolute"
                        top="0"
                        right="0"
                        cursor="pointer"
                        w="26px"
                        h="26px"
                        backgroundColor="white"
                        textAlign="center"
                        onClick={closeModal}
                    >
                        <FontAwesomeIcon 
                            icon={faXmark} 
                            color="black"
                            style={{
                                fontSize: "20px",
                                marginTop: "3px"
                            }}
                        />
                    </Box>
                    <ModalHeader
                        h="20px"
                        backgroundColor="black"
                        color="white"
                        textAlign="center"
                        fontSize="14px"
                        pt={3}
                        pb={3}
                    >
                        長   按   螢   幕   儲   存   圖   片
                    </ModalHeader>
                    <ModalBody
                        overflow="auto"
                    >
                        <Image
                            src={src}
                            w="100%"
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default ResultModal;