import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, HStack, Image, VStack, Text, Flex, Spacer } from "@chakra-ui/react";
import Divider from '../components/input/Divider'; 
import Footer from '../components/Footer'; 
import ResultModal from '../components/ResultModal';
// import IconFacebook from '../assets/image/icon-fb.png';
import IconInstagram from '../assets/image/icon-ig.png';
import IconInstagramHint from '../assets/image/icon-ig-hint.png';
import IconLine from '../assets/image/icon-line.png';
import IconShare from '../assets/image/icon-share.png';

const ResultPage = () => {
    const navigate = useNavigate();
    const { test_code, code } = useParams();
    // const show_image = localStorage.getItem("show_img_url");
    // const result_image = localStorage.getItem("result_img_url");
    // const code = localStorage.getItem("code");
    const show_image = "https://eporttw.com/uploads/prior_test/" + test_code + "/" + code + "-show.png";
    const result_image = "https://eporttw.com/uploads/prior_test/" + test_code + "/" + code + "-result.png";
    const [bgColor, setBgColor] = useState("#fff");
    const [buttonColor, setButtonColor] = useState("#fff");
    const [dividerColor, setDividerColor] = useState("#fff");
    const [shareTextColor, setShareTextColor] = useState("#fff");
    const [isOpen, setIsOpen] = useState(false);
    
    useEffect(() => {
        switch (code) {
            case "R":
                setBgColor("#1B67B2");
                setButtonColor("#3297E3");
                setDividerColor("#FFF");
                setShareTextColor("#FFF");
                break;
            case "I":
                setBgColor("#FDF6C0");
                setButtonColor("#F5B518");
                setDividerColor("#B7B7B7");
                setShareTextColor("#3D3D3D");
                break;
            case "A":
                setBgColor("#F0E9F8");
                setButtonColor("#8266A5");
                setDividerColor("#B7B7B7");
                setShareTextColor("#3D3D3D");
                break;
            case "S":
                setBgColor("#D3E3A5");
                setButtonColor("#6A8128");
                setDividerColor("#B7B7B7");
                setShareTextColor("#3D3D3D");
                break;
            case "E":
                setBgColor("#FFCFCF");
                setButtonColor("#EF9072");
                setDividerColor("#B7B7B7");
                setShareTextColor("#3D3D3D");
                break;
            case "C":
                setBgColor("#FFD1AF");
                setButtonColor("#FF8A00");
                setDividerColor("#B7B7B7");
                setShareTextColor("#3D3D3D");
                break;
            default:    
                setBgColor("#fff");
                setButtonColor("#000");
                setDividerColor("#000");
                setShareTextColor("#000");
        }
    }, []);

    useEffect(() => {
        fetch(result_image)
            .then(response => response)
            .then(data => {
                if (data.status !== 200) {
                    alert("測驗結果不存在，請重新測驗");
                    window.history.pushState({}, null, "/");
                    navigate("/", { replace: true });
                }
            }
        );
    }, [show_image]);

    const goEportEvent = () => {
        let u = navigator.userAgent,
        ua = navigator.userAgent.toLowerCase();
        let isInstagram = u.indexOf("Instagram") > -1 ? true : false; // Instagram 內建瀏覽器
        if(isInstagram) {
            alert("請複製連結至手機瀏覽器開啟，或是點選右上角的「開啟瀏覽器」，就可以前往推薦頁面囉～");
        }
        else {
            const url = "https://eporttw.com/prior/test/show/result/" + test_code;
            window.open(url, "_blank");
        }
    };

    const saveResultImage = () => {
        setIsOpen(true);
    };

    const goTestAgain = () => {
        window.history.pushState({}, null, "/");
        navigate("/", { replace: true });
    };

    const shareResult = async () => {
        if (navigator.share) {
            let title = "";
            let text = "";
            switch (code) {
                case "R":
                    title = "我是「神之手」，你呢？";
                    text = "我是「神之手」，沒有我做不出來的東西！想探索你的特殊技能嗎？快上 What’s Special 兔 You 把你的測驗結果告訴我吧！"
                    break;
                case "I":
                    title = "我是「金頭腦」，你呢？";
                    text = "我是「金頭腦」，請叫我行走的百科全書！想探索你的特殊技能嗎？快上 What’s Special 兔 You 把你的測驗結果告訴我吧！"
                    break;
                case "A":
                    title = "我是「靈的轉移」，你呢？";
                    text = "我是「靈的轉移」，你永遠get不到我！想探索你的特殊技能嗎？快上 What’s Special 兔 You 把你的測驗結果告訴我吧！"
                    break;
                case "S":
                    title = "我是「心靈捕手」，你呢？";
                    text = "我是「心靈捕手」，好人好事代表！想探索你的特殊技能嗎？快上 What’s Special 兔 You 把你的測驗結果告訴我吧！"
                    break;
                case "E":
                    title = "我是「火眼金睛」，你呢？";
                    text = "我是「火眼金睛」，聽說是暴發戶潛力股！想探索你的特殊技能嗎？快上 What’s Special 兔 You 把你的測驗結果告訴我吧！"
                    break;
                case "C":
                    title = "我是「小心肝」，你呢？";
                    text = "我是「小心肝」，永遠處在爆肝邊緣！想探索你的特殊技能嗎？快上 What’s Special 兔 You 把你的測驗結果告訴我吧！"
                    break;
                default:
                    title = "我是「未知動物」，你呢？";
                    text = "？？？？？？？？？？？？？？？！想探索你的特殊技能嗎？快上 What’s Special 兔 You 把你的測驗結果告訴我吧！"
            }

            const blob = await fetch(result_image).then(r=>r.blob())
			navigator.share({
				title: title,
				url: 'https://special-rabbit.eporttw.com/',
				text: text,
                files: [
                    new File([blob], 'file.png', {
                        type: blob.type,
                    }),
                ],
			})
			.then(() => {
				console.log('Thanks for sharing!');
			})
			.catch(console.error);
		} 
    };

    const goIG_AR = () => {
        let url = "";
        switch (code) {
            case "R":
                url = "https://www.instagram.com/ar/160843500099493/";
                break;
            case "I":
                url = "https://www.instagram.com/ar/1200497854173899/";
                break;
            case "A":
                url = "https://www.instagram.com/ar/148959761345095/";
                break;
            case "S":
                url = "https://www.instagram.com/ar/930613388379039/";
                break;
            case "E":
                url = "https://www.instagram.com/ar/1280248286035614/";
                break;
            case "C":
                url = "https://www.instagram.com/ar/1291257884773548/";
                break;
            default:
                url = "https://www.instagram.com/ar/160843500099493/";
        }
        window.open(url, "_blank");
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            <Image 
                src={show_image}
                w="100%"
            />
            <VStack
                mt="-100px !important"
                pb="1.5rem"
                w="auto"
                pl={10}
                pr={10}
                backgroundColor={bgColor}
            >
                <Box
                    w="70%"
                    backgroundColor={buttonColor}
                    mt={10}
                    pt={16}
                    pb={16}
                    left="15%"
                    textAlign="center"
                    borderRadius={25}
                    cursor="pointer"
                    color="white"
                    style={{
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                    onClick={goEportEvent}
                    className="btn-go-eport"
                >
                    查看適合你的課程活動
                </Box>
                <HStack
                    w="100%"
                    mt="50px !important"
                >
                    <Box
                        w="calc(50% - 10px)"
                        backgroundColor="white"
                        color="#3D3D3D"
                        pt={16}
                        pb={16}
                        mr={10}
                        textAlign="center"
                        borderRadius={25}
                        cursor="pointer"
                        onClick={saveResultImage}
                        style={{
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        }}
                        className="btn-save-image"
                    >
                        儲存結果圖片
                    </Box>
                    <Box
                        w="calc(50% - 10px)"
                        backgroundColor="white"
                        color="#3D3D3D"
                        pt={16}
                        pb={16}
                        ml={10}
                        textAlign="center"
                        borderRadius={25}
                        cursor="pointer"
                        onClick={goTestAgain}
                        style={{
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        }}
                        className="btn-test-again"
                    >
                        再測一次
                    </Box>
                </HStack>
                <Box
                    mt="0 !important"
                    w="calc(100% - 20px)"
                    pl={10}
                    pr={10}
                >
                    <Divider 
                        mt="1.5rem"
                        mb="1.5rem"
                        color={dividerColor}
                        opacity="0.5"
                    />
                </Box>
                <Text
                    color={shareTextColor}
                    textAlign="center"
                    w="100%"
                >
                    分享測驗給朋友，一起的探索特殊技能!
                </Text>
                <Box
                    w="100%"
                    position="relative"
                >
                    <Image
                        src={IconInstagramHint}
                        w="50px"
                        ml="calc(20% - 50px)"
                        position="absolute"
                    />
                    <Flex
                        w="60%"
                        ml="20%"
                        mt="15px !important"
                    >
                        <Image
                            src={IconInstagram}
                            w="50px"
                            h="50px"
                            cursor="pointer"
                            onClick={goIG_AR}
                            className="btn-instagram"
                        />
                        <Spacer />
                        <Image
                            src={IconLine}
                            w="50px"
                            h="50px"
                            cursor="pointer"
                            onClick={shareResult}
                            className="btn-line"
                        />
                        <Spacer />
                        <Image
                            src={IconShare}
                            w="50px"
                            h="50px"
                            cursor="pointer"
                            onClick={shareResult}
                            className="btn-share"
                        />
                    </Flex>
                </Box>                
            </VStack>
            <Footer />
            <ResultModal 
                isOpen={isOpen}
                src={result_image}
                closeModal={closeModal}
            />
        </>
    )
};

export default ResultPage;