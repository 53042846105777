import { Box, Image, Text, VStack } from "@chakra-ui/react";
import background from "../../assets/image/p4-bg.png";
import mainImg from "../../assets/image/p4-main.gif";

const HeaderPage4 = () => {
    return (
        <>
            <Box>
                <Image
                    src={background} 
                    w="100vw"
                    h="auto"
                    zIndex="-1"
                />
                <Box
                    position="absolute"
                    top="100px"
                    left="2.5vw"
                    w="95vw"
                >
                    <VStack
                        spacing="1em"
                        position="absolute"
                        w="80%"
                        left="10%"
                    >
                        <Text 
                            fontSize="18px"
                            color="white"
                            w="100%"
                            align="center"
                            margin={0}
                        >
                            「回到小時候吧！(某種魔法)」
                        </Text>
                        <Text 
                            fontSize="16px"
                            color="white"
                            w="100%"
                            align="center"
                            margin={0}
                            mt="1em"
                        >
                            你經過魔法社團，不小心被魔法擊中，看到孩童時期的自己...
                        </Text>
                    </VStack>
                    <Image 
                        src={mainImg} 
                        w="100%"
                        h="auto"
                        zIndex=""
                        mt="7rem"
                    />
                </Box>
            </Box>
        </>
    );
}

export default HeaderPage4;