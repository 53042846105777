import React, { useEffect, useState } from 'react';
import { Box, Image, Text, VStack } from "@chakra-ui/react";
import background from "../../assets/image/p2-bg.png";
import bubble from "../../assets/image/p2-bubble.png";
import mainImg from "../../assets/image/p2-main.gif";

const HeaderPage2 = () => {
    const [nickname, setNickname] = useState("");

    useEffect(() => {
        setNickname(localStorage.getItem("nickname") || "");
    }, []);

    return (
        <>
            <Box 
                mb="3rem"
            >
                <Image
                    src={background} 
                    w="100vw"
                    h="auto"
                    zIndex="-1"
                />
                <Box
                    position="absolute"
                    top="80px"
                    left="0vw"
                    w="100vw"
                >
                    <Image 
                        src={mainImg} 
                        w="100%"
                        h="auto"
                        zIndex=""
                    />
                    <VStack
                        position="absolute"
                        w="80%"
                        left="10%"
                        pt="1rem"
                    >
                        <Image 
                            src={bubble} 
                            w="100%"
                            h="auto"
                            zIndex="0"
                            position="absolute"
                        />
                        <Text 
                            fontSize="16px"
                            color="black"
                            w="100%"
                            align="center"
                            margin={0}
                            mt="2.5rem !important"
                            zIndex="1"
                        >
                            歡迎 {nickname} 加入
                        </Text>
                        <Text 
                            fontSize="16px"
                            color="black"
                            w="100%"
                            align="center"
                            margin={0}
                            zIndex="1"
                        >
                            可以再多說說你自己嗎？
                        </Text>
                        <Text
                            fontSize="16px"
                            color="#1B67B2"
                            mt="2.5rem !important"
                        >
                            於是你告訴兔子自己的特質...
                        </Text>
                    </VStack>
                </Box>
            </Box>
        </>
    );
}

export default HeaderPage2;