import { Box, Image } from "@chakra-ui/react";
import Eport from '../assets/image/eport.png';

const Footer = () => {
    return (
        <>
            <Box
                w="100%"
                h="auto"
                pt={10}
                pb={15}
                backgroundColor="#00478C"
                textAlign="center"
                fontSize="12px"
                color="#BCDEF6"
            >
                <Image
                    src={Eport}
                    w="30%"
                    position="relative"
                    top={3}
                />
                <span style={{
                    marginRight: '10px',
                    marginLeft: '10px',
                }}>|</span>
                高中職生自主學習資源入口平台
            </Box>
        </>
    );
}

export default Footer;