import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Center, Image, Fade } from "@chakra-ui/react";
import background from "../assets/image/home-bg.gif";
import title from "../assets/image/home-title.png";
import fly from "../assets/image/home-fly.gif";
import explore from "../assets/image/home-explore.png";
import start_test from "../assets/image/home-button.png";

const HomePage = () => {
    const navigate = useNavigate();
    const [start1, setStart1] = useState(false);
    const [start2, setStart2] = useState(false);

    useEffect(() => {
        let u = navigator.userAgent,
        ua = navigator.userAgent.toLowerCase();
        let isInstagram = u.indexOf("Instagram") > -1 ? true : false; // Instagram 內建瀏覽器
        if(isInstagram) {
            alert("請複製連結至手機瀏覽器開啟，或是點選右上角的「開啟瀏覽器」");
        }

        localStorage.clear();
        setTimeout(() => {
            setStart1(true);
        }, 400);
        setTimeout(() => {
            setStart2(true);
        }, 1000);

        const fly = document.getElementById("fly");
        let left = -1000;
        let top = 200;
        let count = 20;
        let flyInterval = setInterval(() => {
            left += 50;
            top -= 10;
            fly.style.marginLeft = left + "px";
            fly.style.marginTop = top + "px";
            count--;
            if (count === 0) {
                clearInterval(flyInterval);
            }
        }, 50);

        setTimeout(() => {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
            });
        }, 2000);
    }, []);

    const handleStartTest = () => {
        window.history.pushState({}, null, "/nickname");
        navigate("/nickname", { replace: true });
    }

    // const bgStyle = {
    //     margin: 0,
    //     padding: 0,
    //     background: `url(${background}) no-repeat center center fixed`,
    //     backgroundSize: "cover",
    //     width: "100vw",
    // };

    return (
        <>
            <Box
                className="bgSizeCover height100"
            >
                <Image 
                    src={background}
                    w="100%"
                    className="height100"
                    position="absolute"
                />
                <Image
                    src={title}
                    w="80%"
                    left="10%"
                    mt="5%"
                    position="absolute"
                />
                <Center position="absolute" color="white" className="height100">
                    <Box position="relative" mb="20%" style={{
                        textAlign: "center",
                        zIndex: "1"
                    }}>
                        <Image 
                            id="fly"
                            src={fly} 
                            w="90%" 
                            h="auto" 
                            mt="200px"
                            ml="-1000px"
                        />
                    </Box>
                </Center>
                <Fade 
                    in={start1}
                    style={{
                        position: "absolute"
                    }}
                >
                    <Box
                        w="100vw"
                        className="height100"
                        position="relative"
                    >
                        <Image 
                            src={explore} 
                            position="relative"
                            w="60%"
                            top="75%"
                            left="20%"
                        />
                    </Box>
                </Fade>
                <Fade 
                    in={start2}
                    style={{
                        position: "absolute"
                    }}
                >
                    <Box
                        w="100vw"
                        className="height100"
                        position="relative"
                    >
                        <Image 
                            src={start_test} 
                            position="relative"
                            w="40%"
                            top="80%"
                            left="30%"
                            cursor="pointer"
                            className="btn-function btn-start-test"
                            onClick={handleStartTest}
                        />
                    </Box>
                </Fade>
            </Box>
        </>
    )
};

export default HomePage;