import { Box, Image, Text, VStack } from "@chakra-ui/react";
import background from "../../assets/image/p6-bg.png";
import bubble from "../../assets/image/p6-bubble.png";
import mainImg from "../../assets/image/p6-main.gif";

const HeaderPage6 = () => {
    return (
        <>
            <Box
                mb="4rem"
            >
                <Image
                    src={background} 
                    w="100vw"
                    h="auto"
                    zIndex="-1"
                />
                <Box
                    position="absolute"
                    top="100px"
                    left="0"
                    w="100vw"
                >
                    <Image 
                        src={bubble} 
                        w="90%"
                        h="170px"
                        zIndex=""
                        left="5%"
                        position="absolute"
                    />
                    <VStack
                        spacing="1em"
                        position="absolute"
                        w="90%"
                        left="5%"
                    >
                        <Text 
                            fontSize="18px"
                            color="black"
                            w="100%"
                            align="center"
                            margin={0}
                            mt="1em"
                        >
                            「真的很感謝你的幫忙!」
                        </Text>
                        <Text 
                            fontSize="16px"
                            color="black"
                            w="80%"
                            left="10%"
                            align="center"
                        >
                            你幫兔子家族度過這次的難關，他們希望你可以留下來生活，而你決定加入下列哪種工作團隊...
                        </Text>
                    </VStack>
                    <Image 
                        src={mainImg} 
                        w="100%"
                        h="auto"
                        zIndex=""
                        mt="11rem"
                    />
                </Box>
            </Box>
        </>
    );
}

export default HeaderPage6;